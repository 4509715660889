<template>
  <div class="com-container active-container" v-loading="loading">
    <div class="active-search">
      <div class="active-search-title">活动筛选</div>
      <div class="active-search-content">
        <div class="content-item">
          <div class="content-item-tit">活动状态</div>
          <el-checkbox
            @change="handleCheckAllChange($event,3, statusList)"
            class="is-check-all"
            v-model="checkAllStatus"
          >全部
          </el-checkbox>
          <el-checkbox-group @change="handleCheckedItemChange($event,3, statusList)"
                             v-model="isStatus">
            <el-checkbox :key="index"
                         :label="item.lableId"
                         border
                         v-for="(item, index) in statusList">{{item.lableName}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div :key="index" class="content-item" v-for="(item, index) in labelList">
          <div class="content-item-tit">{{item.categoryName}}</div>
          <el-checkbox
            @change="handleCheckAllChange($event,1, item.labelLists, index)"
            class="is-check-all"
            v-model="checkAll[index]"
          >全部
          </el-checkbox>
          <el-checkbox-group @change="handleCheckedItemChange($event,1, item.labelLists, index)"
                             v-model="selectIds[index]">
            <el-checkbox :key="index2"
                         :label="item2.lableId"
                         border
                         v-for="(item2, index2) in item.labelLists">{{item2.lableName}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="content-item">
          <div class="content-item-tit">活动天数</div>
          <el-input :min="1"
                    class="ml"
                    oninput="value=value.replace(/^0/g, '')"
                    placeholder="最小天数"
                    type="number"
                    v-model="queryForm.minDay"
          ></el-input>
          <div class="line">——</div>
          <el-input :min="1"
                    oninput="value=value.replace(/^0/g, '')"
                    placeholder="最大天数"
                    type="number"
                    v-model="queryForm.maxDay"
          ></el-input>
        </div>
        <div class="content-item">
          <div class="content-item-tit">本站报名</div>
          <el-checkbox
            @change="handleCheckAllChange($event,2, supportList)"
            class="is-check-all"
            v-model="checkAllSupp"
          >全部
          </el-checkbox>
          <el-checkbox-group @change="handleCheckedItemChange($event,2, supportList)"
                             v-model="isSupport">
            <el-checkbox :key="index"
                         :label="item.lableId"
                         border
                         v-for="(item, index) in supportList">{{item.lableName}}
            </el-checkbox>
            <div class="btn-area">
              <el-button @click="resetHandle">重置</el-button>
              <el-button @click="searchHandle">确定</el-button>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <template v-if="activeList.length>0">
      <div class="active-list">
        <div :key="index" :style="animationTime(index)" class="active-list-item"
             v-for="(item, index) in activeList">
          <div class="inner">
            <div class="active-list-item-bg">
              <el-image :src="item.activityCover" fit="cover"></el-image>
            </div>
            <div class="active-list-item-cont">
              <div class="active-list-item-cont-header">
                <div class="label" v-if="item.activityLabel">
                <span :key="index2"
                      v-for="(item2, index2) in item.activityLabel.split(',')">{{item2}}</span>
                </div>
                <div class="title">{{item.name}}</div>
              </div>
              <div class="active-list-item-cont-txt1">
                <div class="sign-box" v-if="item.schoolName">
                  <div :key="index2"
                       class="sign"
                       v-for="(item2, index2) in item.schoolName.split(',')">{{item2}}
                  </div>
                </div>
                <div class="sign-box" v-if="item.subjectName">
                  <div :key="index2"
                       class="sign"
                       v-for="(item2, index2) in item.subjectName.split(',')">{{item2}}
                  </div>
                </div>
              </div>
              <div class="active-list-item-cont-txt2" v-if="item.activityStartTime!=null">
                {{formatYMD(item.activityStartTime)}} （共{{item.activityTime}}天）
              </div>
              <div class="active-list-item-cont-txt3" v-if="item.bmEndTime!=null">
                报名截止时间：{{formatYMDChina(item.bmEndTime)}}
              </div>
            </div>
            <div @click="toActive(item.id)" class="active-list-item-bottom">
              <div>查看详情</div>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-area">
        <el-pagination
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </template>
    <div class="active-list" v-else>
      <el-empty description="暂无数据" style="margin: 0 auto;"></el-empty>
    </div>
  </div>
</template>

<script>
  import {HotList, LabelType} from "r/base/active";

  export default {
    name: "Active",
    data() {
      return {
        loading: false,
        queryForm: {
          ids: {},
          activityStatus: null,
          isSupport: null,
          minDay: null,
          maxDay: null,
          pageNum: 1,
          pageSize: 20,
        },
        total: 0,
        labelList: [],
        activeList: [],// 通过后台数据得到的所有数据
        // showActiveList: [],// 前端页面需要渲染的数据
        // pageSize: 8, //渲染数据的多少根据实际情况设置
        // baseSize: 8,// 渲染基础页数
        // totall: 0, //总数据量

        statusList: [
          {
            lableId: 1,
            lableName: '报名未开放'
          },
          {
            lableId: 2,
            lableName: '报名中'
          },
          {
            lableId: 3,
            lableName: '即将开营'
          },
          {
            lableId: 4,
            lableName: '开营中'
          },
          {
            lableId: 5,
            lableName: '已结营'
          },
        ],
        supportList: [
          {
            lableId: 2,
            lableName: '支持'
          },
          {
            lableId: 1,
            lableName: '不支持'
          },
        ],
        selectIds: {},
        checkAll: {},
        isStatus: [],
        checkAllStatus: false,
        isSupport: [],
        checkAllSupp: false,
      }
    },
    created() {
      this.commonApi(39)
      this.getLabelType();
      this.getList();
    },
    computed: {
      // 防抖
      debounce() {
        let timer = ''
        return function (func, wait) {
          let context = this;
          timer && clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(context);
          }, wait)
        }
      }
    },
    // mounted() {
    //   window.addEventListener('scroll', this.dataScroll, true)
    // },
    // destroyed() {
    //   window.removeEventListener('scroll', this.dataScroll, true)
    // },
    methods: {
      // // 页面滚动获取数据
      // scrollLoadData() {
      //   //获取滚动条的高度
      //   let scrollTop = document.documentElement.scrollTop;
      //   // 获取视口高度
      //   let windowHeight = window.innerHeight;
      //   //获取页面内容总高度
      //   let scrollHeight = document.querySelector('.active-container').scrollHeight;
      //   // 滚动到指定位置 并且当前显示的数量小于总数
      //   if ((scrollTop + windowHeight + 100 >= scrollHeight) && (this.pageSize < this.activeList.length)) {
      //     this.pageSize += this.baseSize;
      //     this.loadData()
      //   }
      // },
      // // 页面滚动方法
      // dataScroll() {
      //   //防抖函数
      //   this.debounce(this.scrollLoadData, 100)
      // },
      // // 处理列表渲染数据
      // loadData() {
      //   for (let i = (this.pageSize - this.baseSize); i < this.pageSize; i++) {
      //     if (this.totall >= this.activeList.length) {
      //       return false;
      //     }
      //     this.showActiveList.push(this.activeList[i]);
      //     this.totall++;
      //   }
      // },

      // 卡片出现的动画时间
      animationTime(index) {
        let time = 0;
        time = index + 1
        // time = index - (this.pageSize / this.baseSize - 1) * this.baseSize + 1;
        return {
          '-webkit-animation-delay': time * 0.1 + 's',
          'imation-delay': time * 0.1 + 's',
        }
      },
      // 分页-改变每页数量
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.getList(true);
      },
      // 分页-改变页数
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.getList(true);
      },
      // 获取列表
      getList(type = false) {
        if (this.loading) {
          return false;
        }
        this.loading = true;
        this.activeList = [];
        let queryForm = JSON.parse(JSON.stringify(this.queryForm));
        // 全选项 置空传值
        let flag = true;
        for (let key in queryForm['ids']) {
          if (!this.checkAll[key]) {
            flag = false;
          }
        }
        if (flag) {
          queryForm['ids'] = {};
        }
        HotList(queryForm).then(res => {
          console.log(res)
          if (res.data.code != 200) {
            this.loading = false;
            return this.$message.error(res.data.msg);
          }
          this.loading = false;
          this.total = res.data.data.total;
          this.activeList = res.data.data.list;
          if (type) {
            document.body.scrollIntoView(true);
          }
          // this.loadData()
        }).catch(err => {
          console.log(err)
          this.loading = false;
        })
      },
      // 获取筛选标签列表
      getLabelType() {
        LabelType({}).then(res => {
          console.log(res)
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          let labelList = res.data.data;
          let selectIds = {};
          let checkAll = {};
          labelList.forEach((item, index) => {
            selectIds[index] = [];
            checkAll[index] = false;
          })
          this.selectIds = selectIds;
          this.checkAll = checkAll;
          this.labelList = labelList;
        }).catch(err => {
          console.log(err)
        })
      },
      // 确定按钮
      searchHandle() {
        // this.pageSize = 8;
        // this.totall = 0;
        // this.showActiveList = [];
        this.total = 0;
        this.queryForm.pageNum = 1;
        this.queryForm.pageSize = 20;
        this.getList();
      },
      // 重置按钮
      resetHandle() {
        // this.pageSize = 8;
        // this.totall = 0;
        // this.showActiveList = [];
        this.total = 0;
        this.queryForm = {
          ids: {},
          activityStatus: null,
          isSupport: null,
          minDay: null,
          maxDay: null,
          pageNum: 1,
          pageSize: 20,
        };
        for (let key in this.selectIds) {
          this.selectIds[key] = [];
        }
        for (let key in this.checkAll) {
          this.checkAll[key] = false;
        }
        this.isSupport = [];
        this.checkAllSupp = false;
        this.isStatus = [];
        this.checkAllStatus = false;
        this.getList();
      },
      // 全部-标签操作
      handleCheckAllChange(val, type = 1, list = [], idx = 0) {
        let lableIdArr = list.map(item => item.lableId);
        let arr = val ? lableIdArr : [];
        if (type == 1) {
          this.selectIds[idx] = arr;
          if (arr.length > 0) {
            this.queryForm.ids[idx] = arr;
          } else {
            delete this.queryForm.ids[idx]
          }
        } else if (type == 2) {
          this.isSupport = arr;
          this.queryForm.isSupport = arr.join(',');
        } else if (type == 3) {
          this.isStatus = arr;
          this.queryForm.activityStatus = arr.join(',');
        }
      },
      // 单个-标签操作
      handleCheckedItemChange(val, type = 1, list = [], idx = 0) {
        let lableIdArr = list.map(item => item.lableId);
        let checkedCount = val.length;
        if (type == 1) {
          this.checkAll[idx] = checkedCount === lableIdArr.length;
          if (checkedCount > 0) {
            this.queryForm.ids[idx] = val;
          } else {
            delete this.queryForm.ids[idx]
          }
        } else if (type == 2) {
          this.checkAllSupp = checkedCount === lableIdArr.length;
          this.queryForm.isSupport = val.join(',');
        } else if (type == 3) {
          this.checkAllStatus = checkedCount === lableIdArr.length;
          this.queryForm.activityStatus = val.join(',');
        }
      },
      //查看详情
      toActive(id) {
        this.commonApi(36, id)
        this.$router.push({
          path: '/active/'+id,
          query: {
            ...this.$store.state.query,
          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "a/scss/common";
  @import "a/scss/active/activeList";

  .active-container {
    padding: 50px;
    color: #333333;
    font-family: "PingFang SC";

    .active-search {
      padding: 20px 25px;
      border-radius: 4px;
      background-color: #ffffff;

      .active-search-title {
        font-size: 20px;
        line-height: 46px;
      }

      .active-search-content {
        .content-item {
          display: flex;
          flex-flow: row;
          /*align-items: center;*/
          line-height: 36px;
          margin: 15px 0;

          .content-item-tit {
            width: 100px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            background-color: #f5f5f5;
            margin-right: 10px;
          }

          ::v-deep .el-checkbox-group {
            flex: 1;
            /*height: 36px;*/
            line-height: 36px;
          }

          ::v-deep .el-checkbox {
            color: #333333;
            border: 0;
            padding: 0 5px;
            margin: 0 0 0 15px;
            height: 36px;
            line-height: 36px;

            .el-checkbox__input {
              display: none;
            }

            .el-checkbox__label {
              padding: 0;
              font-size: 14px;
            }

            /*&:hover,*/
            &.is-checked {
              .el-checkbox__label {
                color: var(--all_color) !important;
              }
            }

            &.is-check-all.is-checked {
              .el-checkbox__label {
                font-weight: bold;
              }
            }
          }

          ::v-deep .el-input {
            width: 130px;
            height: 36px;
            line-height: 36px;

            &.ml {
              margin-left: 25px;
            }

            .el-input__inner {
              height: 36px;
              line-height: 36px;
              color: #333333;
              border-color: #333333;

              &:focus,
              &:hover {
                color: #000000;
                border-color: var(--all_color);
              }
            }
          }

          .line {
            color: #333333;
            margin: 0 10px;
            display: inline-block;
          }

          .btn-area {
            display: inline-block;
            float: right;
            margin-right: 200px;


            .el-button {
              height: 36px;
              color: #333333;
              border-color: #333333;
              background-color: #ffffff;
              margin-left: 30px;

              &:hover {
                color: var(--all_color);
                border-color: var(--all_color);
                background-color: #ffffff;

              }
            }
          }

        }
      }
    }

    ::v-deep .el-loading-spinner {
      top: 200px !important;
    }

    .pagination-area {
      text-align: right;
      padding: 20px 0 0;

      ::v-deep .el-pagination {
        .el-pager .number {
          &:hover {
            color: var(--all_color);
          }

          &.active {
            color: #ffffff;
            background-color: var(--all_color);
          }
        }


        .el-input__inner {
          &:focus {
            border-color: var(--all_color);
          }
        }

        .btn-prev,
        .btn-next {
          &:hover {
            color: var(--all_color);
          }

          &:disabled {
            color: #C0C4CC;
          }
        }
      }

    }
  }
</style>
